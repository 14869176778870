import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  AssortmentCategoryOptionFragment,
  AssortmentFragment,
  useAssortmentCategoryOptionsQuery,
} from 'Apollo/graphql';
import {
  FormInputAutocomplete,
  FormInputCheckbox,
  FormInputFileUpload,
  FormInputText,
} from 'Components/Form';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { IMAGE_MAX_SIZE } from 'Utils/constants';
import {
  areOptionsEqualById,
  getOptionFieldName,
  renderOptionByIdName,
} from 'Utils/helpers';
import { useTranslation } from 'react-i18next';

interface Props {
  assortment?: AssortmentFragment;
}

const AssortmentForm = ({ assortment }: Props): JSX.Element => {
  const { t } = useTranslation('assortmentAdmin');
  const categoriesOptionsResult = useAssortmentCategoryOptionsQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Grid container spacing={3}>
      {assortment && (
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('assortment.labelProductId')}>
            {assortment.id}
          </NotEditableTextClassic>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormInputText name="name" label={t('assortment.labelName')} required />
      </Grid>
      <Grid item xs={12}>
        <FormInputFileUpload
          name="image"
          label={t('assortment.labelPhoto')}
          required
          fileType="image"
          maxSize={IMAGE_MAX_SIZE}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name="description"
          label={t('assortment.labelDescription')}
          required
          multiline
          minRows={4}
          maxRows={6}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputAutocomplete<AssortmentCategoryOptionFragment>
          name="category"
          getOptionLabel={getOptionFieldName}
          isOptionEqualToValue={areOptionsEqualById}
          renderOption={renderOptionByIdName}
          options={categoriesOptionsResult.data?.assortmentCategories || []}
          loading={categoriesOptionsResult.loading}
          label={t('assortment.labelCategory')}
          placeholder={t('common:select.placeholder')}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputCheckbox
          name="notificationsVisible"
          label={
            <Typography>
              {t('common:notification.createPopUpNotification')}
            </Typography>
          }
        />
      </Grid>
    </Grid>
  );
};

export default AssortmentForm;
