import create from 'zustand';
import {
  AssortmentCategoryOptionFragment,
  AssortmentFragment,
  AssortmentSortableFields,
  OrderBy,
} from 'Apollo/graphql';
import {
  ChangeTableFilter,
  ChangeTableSort,
  ChangeTableData,
} from 'Utils/types';

export type TableAssortmentsFilter = {
  category: AssortmentCategoryOptionFragment | null;
  description: string;
  name: string;
  id: string;
};

export type AssortmentStore = {
  modalCreateAssortment: {
    isOpen: boolean;
  };
  modalEditAssortment: {
    isOpen: boolean;
    assortment: AssortmentFragment | null;
  };
  modalRemoveAssortment: {
    isOpen: boolean;
    assortment: AssortmentFragment | null;
  };
  tableAssortments: {
    data: {
      sortField: AssortmentSortableFields | null;
      sortOrder: OrderBy | null;
      limit: number;
      offset: number;
    };
    filter: TableAssortmentsFilter;
  };
  openModalCreateAssortment: () => void;
  closeModalCreateAssortment: () => void;
  openModalEditAssortment: (assortment: AssortmentFragment) => void;
  closeModalEditAssortment: () => void;
  openModalRemoveAssortment: (assortment: AssortmentFragment) => void;
  closeModalRemoveAssortment: () => void;
  changeTableData: ChangeTableData;
  changeTableFilter: ChangeTableFilter;
  changeTableSort: ChangeTableSort;
};

export const useAssortmentStore = create<AssortmentStore>((set) => ({
  modalCreateAssortment: {
    isOpen: false,
  },
  modalEditAssortment: {
    isOpen: false,
    assortment: null,
  },
  modalRemoveAssortment: {
    isOpen: false,
    assortment: null,
  },
  tableAssortments: {
    data: {
      sortField: AssortmentSortableFields.Order,
      sortOrder: OrderBy.Asc,
      limit: 10,
      offset: 0,
    },
    filter: {
      category: null,
      description: '',
      name: '',
      id: '',
    },
  },

  /*
   * Modal Create
   */
  openModalCreateAssortment: () =>
    set({
      modalCreateAssortment: {
        isOpen: true,
      },
    }),
  closeModalCreateAssortment: () =>
    set({
      modalCreateAssortment: {
        isOpen: false,
      },
    }),

  /*
   * Modal Edit
   */
  openModalEditAssortment: (assortment) =>
    set({
      modalEditAssortment: {
        isOpen: true,
        assortment,
      },
    }),
  closeModalEditAssortment: () =>
    set((state) => ({
      modalEditAssortment: {
        ...state.modalEditAssortment,
        isOpen: false,
      },
    })),

  /*
   * Modal Remove
   */
  openModalRemoveAssortment: (assortment) =>
    set({
      modalRemoveAssortment: {
        isOpen: true,
        assortment,
      },
    }),
  closeModalRemoveAssortment: () =>
    set((state) => ({
      modalRemoveAssortment: {
        ...state.modalRemoveAssortment,
        isOpen: false,
        assortment: null,
      },
    })),

  /**
   * Assortment table
   */
  changeTableData: (name, value) =>
    set((state) => ({
      tableAssortments: {
        ...state.tableAssortments,
        data: {
          ...state.tableAssortments.data,
          [name]: value,
        },
      },
    })),
  changeTableFilter: (name, value) =>
    set((state) => ({
      tableAssortments: {
        ...state.tableAssortments,
        data: {
          ...state.tableAssortments.data,
          offset: 0,
        },
        filter: {
          ...state.tableAssortments.filter,
          [name]: value,
        },
      },
    })),
  changeTableSort: (sortField, sortOrder) =>
    set((state) => ({
      tableAssortments: {
        ...state.tableAssortments,
        data: {
          ...state.tableAssortments.data,
          sortField: sortField as AssortmentSortableFields,
          sortOrder,
        },
      },
    })),
}));
